@import "variables";

#footer {
    position: relative;
    margin-left: 20%;
    margin-top: 40px;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .social-networks-wrapper {
        display: none;
    }

    #logo_footer {
        img {
            max-width: 150px;
        }
    }

    .footerpages {
        list-style: none;
        display: block;
        margin: 0;
        padding: 0;

        li.nav-item {
            display: inline-block;

            &:before {
                content: '';
            }

        }

        li.nav-item:first-child {
            &:before {
                display: none;
            }
        }

        .nav-link {
            color: $c-grey;
            padding: 0 15px;
            display: inline;
            font-size: 13px;
        }
    }
}

@media all and (max-width: 1139px) {
    #footer {
        margin-left: 250px;
    }
}

@media all and (max-width: 991px) {
    #footer {
        margin-left: 0px;
    }
}

@media all and (max-width: 767px) {
    #footer  {
        .social-networks-wrapper {
            width: 100%;
            display: flex;
            justify-content: center;
            padding-bottom: 25px;

            .social-links {
                font-size: 35px;
                display: block;
                margin: 15px 40px;
            }
        }
        .footerpages {
            float: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    #footer .footerpages li.nav-item {
        margin: 15px 0;
    }
}

