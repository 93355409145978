@import "variables";

/********************************  SITEMAP ***********************************************/
#sitemap{
    ul li{
      list-style: none;
        a{
          font-family: $font-secondary;
          color: $color-secondary;
          font-size: 22px;
          font-weight: 700;
          padding: 10px 0px;
          text-transform: uppercase;
        }

        ul li a{
          font-family: $font-secondary;
          color: $color-third;
          font-weight: 600;
        }
    }
}



/********************************  NewsBundle ***********************************************/
.news_index{
   .new{
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 2px solid $color-secondary;
   }
}

/********************************  SliderBundle ***********************************************/

/* Slider */
.slider_principal{
  margin-bottom: 40px;
}
.slider{
  position: relative;

}
.slick-track
{
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-slide{
  float:left;
  overflow:hidden;
  text-align:center;
}
.slick-list{
  overflow: hidden;
}
.slick-next{
  position: absolute;
  z-index: 90;
  top: 50%;
  right: 30px;
  font-size: 0px;
  border: none;
  color: $color-secondary;
  border: 2px solid $color-secondary;
  background: none;
  width: 43px;
  height: 43px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -22px;
  padding-right: 2px;

  &:before{
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    content: "\F105";
    font-size: 28px;
  }
}
.slick-prev{
  position: absolute;
  z-index: 90;
  top: 50%;
  left: 30px;
  font-size: 0px;
  border: none;
  color: $color-secondary;
  border: 2px solid $color-secondary;
  background: none;
  width: 43px;
  height: 43px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -22px;
  padding-left: 2px;

  &:before{
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    content: "\F104";
    font-size: 28px;
  }
}