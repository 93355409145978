.menu-container {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    background-color: $c-grey;
    width: 20%;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .logo-wrapper {
        text-align: center;
        position: relative;

        &:after {
            content: attr(data-content-after);
            position: absolute;
            display: block;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: -30px;
            color: #191919;
            font-size: 14px;
        }
    }
    .links-wrapper {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .menu-links {
            @extend .transition-main;
            margin: 20px 0;
            padding: 0 30px;
            display: block;
            height: 30px;
            line-height: 30px;
            font-size: 17px;
            font-weight: bold;
            color: #fff;
            background: transparent;
            border: 2px solid transparent;
            border-radius: 30px 30px 0 30px;
            text-align: center;

            &:hover {
                color: $c-green;
            }
            &.active {
                height: 50px;
                line-height: 50px;
                background: $c-green;
                border-color: $c-green;
                &:hover {
                    color: $c-green;
                    background: #fff;
                }
            }

        }
    }
    .menu-contact-wrapper {
        display: flex;
        justify-content: space-around;
        width: 100%;
        .menu-contact-link {
            position: relative;
            padding-top: 50px;
            color: $c-green;
            font-weight: 600;

            .link-label {
                font-family: $font-main !important;
            }

            &:before {
                display: block;
                width: 40px;
                height: 40px;
                border: 3px solid $c-green;
                border-radius: 50%;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                margin: 0 auto;
                font-size: 20px;
                line-height: 34px;
                text-align: center;
            }
        }
    }
    .social-networks-wrapper {
        display: flex;
        justify-content: center;
        .social-links {
            font-size: 25px;
            color: #fff;
            padding: 25px;
        }
    }
}

@media all and (max-width: 1400px) {
    .menu-container {
        padding: 30px 0px;
    }
    .menu-container .logo-wrapper {
        padding: 0 15px;
    }
}

@media all and (max-width: 1139px) {
    .menu-container {
        min-width: 250px;

        .pt-60 {
            padding-top: 40px;
        }
        .pb-60 {
            padding-bottom: 40px;
        }
        .pt-50 {
            padding-top: 35px;
        }
        .pb-50 {
            padding-bottom: 35px;
        }
        .links-wrapper .menu-links {
            margin: 10px 0;
            display: block;
            height: 40px;
            line-height: 40px;
        }
        .social-networks-wrapper .social-links {
            padding: 10px 25px;
        }
    }
}


@media all and (max-width: 991px) {
    .menu-container {
        position: relative;
        width: 100%;
        min-height: auto;
        height: auto;
        padding: 10px 10px 15px 10px;

        .logo-wrapper {
            max-width: 250px;
            padding-bottom: 30px;
            margin: 0 auto;
            &:after {
                bottom: 0;
                left: 0;
                right: auto;
            }

        }
        .links-wrapper {
            display: none;
        }
        .social-networks-wrapper {
            position: absolute;
            left: 0;
            top: 55px;
        }
        .menu-contact-wrapper {
            position: absolute;
            right: 0;
            top: 0;
            padding: 20px;
            display: flex;
            flex-direction: column;
            height: 100%;
            align-items: flex-end;

            .menu-contact-link {
                width: 50px;
                .link-label {
                    display: none;
                }
            }
        }
    }
}

@media all and (max-width: 500px) {
    .menu-container {

        .logo-wrapper {
            margin-left: 0;
            &:after {

            }

        }
        .links-wrapper {

        }
        .social-networks-wrapper {
            display: none;
        }
        .menu-contact-wrapper {

        }
    }
}