.map-view-block {

    .map-container {
        padding-top: 25px !important;
        padding-bottom: 25px !important;
    }

    #map {

        height: 600px;
        width: calc(100% + 45px);
        margin-left: -45px;

        .leaflet-left .leaflet-control {
            margin-left: 60px;
        }
    }

    .filter-container {

        position: relative;
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
        background-color: #fff;
        z-index: 500;
        box-shadow: 10px 0px 32px 0px rgba(0,0,0,0.1);

        h2 {
            text-transform: inherit;
        }

        .inner {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
        }
    }

    .filters-list {

        li {

            a {

                position: relative;
                display: inline-block;
                width: 100%;
                padding: 25px;
                border-bottom: 1px solid #f0f0f0;

                &:hover {
                    color: initial;
                }

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    width: 80px;
                    background: url("../../../../images/map-eye.svg");
                    background-size: 50% !important;
                    background-repeat: no-repeat !important;
                    background-position: 40px 45% !important;
                }

                &.active {

                    font-weight: bold;

                    &:after {
                        background: url("../../../../images/map-check.svg");
                    }
                }
            }
        }
    }
}