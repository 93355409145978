/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main,
.hover-to-green,
.hover-to-white,
.bt_learn_more,
a.file,
input[type=submit],
.block .visual_container .link,
.block .visual_container .zoom,
.contact_view .btn.btn-primary,
.menu-container .links-wrapper .menu-links {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

.txt-green {
  color: #9dc82d;
}

.txt-grey {
  color: #858585;
}

.txt-white {
  color: #fff;
}

.bg-green {
  background-color: #9dc82d;
}

.bg-grey {
  background-color: #858585;
}

.bg-light-grey {
  background-color: #e4e4e4;
}

.bg-white {
  background-color: #fff;
}

.light {
  font-weight: 300;
}

.normal {
  font-weight: 400;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.border-vertige {
  border-radius: 25px 25px 0 25px;
}

.vague-container {
  display: block;
  margin: 0 -2px;
}

.vague {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.f-small {
  font-size: 14px;
}

.f-normal {
  font-size: 17px;
}

.f-intro {
  font-size: 25px;
}

.f-big {
  font-size: 25px;
}

.f-bigger {
  font-size: 30px;
}

.f-title {
  font-size: 50px;
}

.vertige-title {
  position: relative;
  font-size: 50px;
  text-align: center;
  padding-top: 35px;
}

.vertige-title .title-before {
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.pb-50 {
  padding-bottom: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.hover-to-green:hover {
  color: #9dc82d !important;
  border-color: #9dc82d !important;
}

.hover-to-white:hover {
  color: #fff !important;
  border-color: #fff !important;
}

@media all and (max-width: 767px) {
  .vertige-title {
    font-size: 30px;
    padding-top: 24px;
  }

  .vertige-title .title-before {
    font-weight: bold;
    font-size: 17px;
  }

  .f-small {
    font-size: 14px;
  }

  .f-normal {
    font-size: 16px;
  }

  .f-intro {
    font-size: 18px;
  }

  .f-big {
    font-size: 18px;
  }

  .f-bigger {
    font-size: 20px;
  }

  .f-title {
    font-size: 30px;
  }

  .pb-50 {
    padding-bottom: 25px;
  }

  .pt-50 {
    padding-top: 25px;
  }

  .pb-60 {
    padding-bottom: 30px;
  }

  .pt-60 {
    padding-top: 30px;
  }

  .pb-70 {
    padding-bottom: 35px;
  }

  .pt-70 {
    padding-top: 35px;
  }

  .mt-80 {
    margin-top: 1.5rem !important;
  }
}

/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main,
.hover-to-green,
.hover-to-white,
.bt_learn_more,
a.file,
input[type=submit],
.block .visual_container .link,
.block .visual_container .zoom,
.contact_view .btn.btn-primary,
.menu-container .links-wrapper .menu-links {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

#editMode {
  position: fixed;
  color: #fff !important;
  top: 0px;
  left: 0px;
  background: #9dc82d;
  padding: 10px 25px;
  border-radius: 0px 0px 5px 0px;
  z-index: 100000;
}

#editMode a {
  color: #ffffff;
}

.content-wrapper {
  position: relative;
  margin-left: 20%;
  padding: 0 15px;
}

@media all and (max-width: 1139px) {
  .content-wrapper {
    margin-left: 250px;
  }
}

@media all and (max-width: 991px) {
  .content-wrapper {
    margin-left: 0px;
  }
}

ul.reset {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  color: #858585;
  text-decoration: none;
}

a:hover {
  color: #9dc82d;
  text-decoration: none;
}

img {
  max-width: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  color: #858585;
}

.introduction {
  font-weight: 700;
  padding-bottom: 5px;
}

#breadcrumb {
  background: #9dc82d;
  color: #ffffff;
  border-radius: 0px;
  font-size: 12px;
  vertical-align: top;
  display: flex;
}

#breadcrumb .ici {
  display: inline-block;
  padding: 11px 0px 0px 15px;
}

#breadcrumb .breadcrumb {
  margin-bottom: 0px;
  background: none;
  color: #ffffff;
}

#breadcrumb .breadcrumb a,
#breadcrumb .breadcrumb .breadcrumb-item.active,
#breadcrumb .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #ffffff;
}

table tr td {
  padding: 5px;
  border: 1px solid #9dc82d;
}

table thead td {
  background: #9dc82d;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
}

.bt_learn_more,
a.file,
input[type=submit] {
  display: inline-block;
  margin: 20px 0px;
  color: #9dc82d;
  border: 1px solid #9dc82d;
  background: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px 15px;
}

.bt_learn_more:hover,
a.file:hover,
input[type=submit]:hover {
  color: #ffffff;
  text-decoration: none;
  background: #9dc82d;
}

#btTop {
  display: inline-block;
  position: fixed;
  bottom: 25px;
  right: 25px;
  opacity: 0.8;
  background: #9dc82d;
  color: #ffffff;
  font-size: 30px;
  text-align: center;
  width: 50px;
  height: 50px;
  z-index: 1000;
  cursor: pointer;
}

.form-group.has-error textarea,
.form-group.has-error select,
.form-group.has-error input {
  border: 1px solid #dc3545 !important;
}

.form-group.has-error .help-block {
  color: #dc3545 !important;
  font-size: 12px;
  margin-top: 3px;
  display: inline-block;
}

@media screen and (max-width: 780px) {
  blockquote {
    font-size: 26px;
  }

  blockquote:after {
    font-size: 50px;
    left: inherit;
    right: 0px;
  }

  #btTop {
    width: 25px;
    height: 25px;
    bottom: 15px;
    right: 15px;
    font-size: 20px;
  }

  h1 {
    font-size: 28px;
  }
}

/***** Responsive Grid => Evo grid responsive ****/

@media all and (max-width: 991px) {
  .col-six-and-threes {
    order: 5;
  }

  .col-six-and-threes.col-lg-6:nth-child(2) {
    order: 1;
  }
}

/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main,
.hover-to-green,
.hover-to-white,
.bt_learn_more,
a.file,
input[type=submit],
.block .visual_container .link,
.block .visual_container .zoom,
.contact_view .btn.btn-primary,
.menu-container .links-wrapper .menu-links {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

#header {
  background: #ffffff;
  position: relative;
}

#header #logo img {
  max-width: 150px;
}

#header #navigation {
  display: inline-block;
}

#header .container {
  position: relative;
}

/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main,
.hover-to-green,
.hover-to-white,
.bt_learn_more,
a.file,
input[type=submit],
.block .visual_container .link,
.block .visual_container .zoom,
.contact_view .btn.btn-primary,
.menu-container .links-wrapper .menu-links {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

.zone.container .block_separator {
  border-bottom: 3px solid #9dc82d;
  margin-bottom: 30px;
}

.zone.row .block_separator {
  -webkit-box-shadow: inset 0 50px 70px -70px #000000;
  box-shadow: inset 0 50px 70px -70px #000000;
  min-height: 70px;
}

.block {
  margin-bottom: 30px;
}

.block .visual_container {
  position: relative;
}

.block .visual_container .link,
.block .visual_container .zoom {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  background: url("./../../../images/link.png") center center #9dc82d no-repeat;
}

.block .visual_container .zoom {
  background: url("./../../../images/zoom.png") center center #9dc82d no-repeat;
}

.block .visual_container:hover .link,
.block .visual_container:hover .zoom {
  opacity: 0.7;
}

.block .body a {
  color: #9dc82d;
}

.block_entete {
  width: 100%;
  position: relative;
}

.chiffre,
.chiffres {
  text-align: center;
  padding: 30px 0px;
}

.chiffre .col,
.chiffres .col {
  border-left: 2px solid #9dc82d;
}

.chiffre .col h2,
.chiffres .col h2 {
  font-size: 50px;
}

.chiffre .col .introduction,
.chiffres .col .introduction {
  font-size: 24px;
  color: #9dc82d;
}

.chiffre .col:nth-child(1),
.chiffres .col:nth-child(1) {
  border: none;
}

/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main,
.hover-to-green,
.hover-to-white,
.bt_learn_more,
a.file,
input[type=submit],
.block .visual_container .link,
.block .visual_container .zoom,
.contact_view .btn.btn-primary,
.menu-container .links-wrapper .menu-links {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

#footer {
  position: relative;
  margin-left: 20%;
  margin-top: 40px;
  padding-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#footer .social-networks-wrapper {
  display: none;
}

#footer #logo_footer img {
  max-width: 150px;
}

#footer .footerpages {
  list-style: none;
  display: block;
  margin: 0;
  padding: 0;
}

#footer .footerpages li.nav-item {
  display: inline-block;
}

#footer .footerpages li.nav-item:before {
  content: '';
}

#footer .footerpages li.nav-item:first-child:before {
  display: none;
}

#footer .footerpages .nav-link {
  color: #858585;
  padding: 0 15px;
  display: inline;
  font-size: 13px;
}

@media all and (max-width: 1139px) {
  #footer {
    margin-left: 250px;
  }
}

@media all and (max-width: 991px) {
  #footer {
    margin-left: 0px;
  }
}

@media all and (max-width: 767px) {
  #footer .social-networks-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 25px;
  }

  #footer .social-networks-wrapper .social-links {
    font-size: 35px;
    display: block;
    margin: 15px 40px;
  }

  #footer .footerpages {
    float: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  #footer .footerpages li.nav-item {
    margin: 15px 0;
  }
}

.map-view-block .map-container {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.map-view-block #map {
  height: 600px;
  width: calc(100% + 45px);
  margin-left: -45px;
}

.map-view-block #map .leaflet-left .leaflet-control {
  margin-left: 60px;
}

.map-view-block .filter-container {
  position: relative;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  background-color: #fff;
  z-index: 500;
  box-shadow: 10px 0px 32px 0px rgba(0, 0, 0, 0.1);
}

.map-view-block .filter-container h2 {
  text-transform: inherit;
}

.map-view-block .filter-container .inner {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.map-view-block .filters-list li a {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 25px;
  border-bottom: 1px solid #f0f0f0;
}

.map-view-block .filters-list li a:hover {
  color: initial;
}

.map-view-block .filters-list li a:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 80px;
  background: url("./../../../images/map-eye.svg");
  background-size: 50% !important;
  background-repeat: no-repeat !important;
  background-position: 40px 45% !important;
}

.map-view-block .filters-list li a.active {
  font-weight: bold;
}

.map-view-block .filters-list li a.active:after {
  background: url("./../../../images/map-check.svg");
}

@media all and (max-width: 480px) {
  #gallery_bloc {
    padding-bottom: 90px;
    position: relative;
    background: #9dc82d;
  }

  #gallery_bloc .slick-prev,
  #gallery_bloc .slick-next {
    bottom: 30px;
    top: auto;
    color: #fff;
    border-color: #fff;
  }
}

.contact_view .form-group {
  margin-bottom: 25px;
}

.contact_view .form-control {
  background: #e4e4e4;
  border-radius: 30px 30px 0 30px;
  border: none;
  height: 50px;
  color: #858585;
  line-height: 50px;
  padding: 0 30px;
}

.contact_view textarea {
  min-height: 200px;
}

.contact_view label[for='form_rgpdAccept'] {
  font-size: 13px;
}

.contact_view .btn.btn-primary {
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
  font-size: 19px;
  border: none;
  background: #9dc82d;
  color: #fff;
  border-radius: 30px 30px 0 30px;
  text-transform: capitalize;
  border: 2px solid #9dc82d;
}

.contact_view .btn.btn-primary:hover {
  color: #9dc82d;
  background: #fff;
}

/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main,
.hover-to-green,
.hover-to-white,
.bt_learn_more,
a.file,
input[type=submit],
.block .visual_container .link,
.block .visual_container .zoom,
.contact_view .btn.btn-primary,
.menu-container .links-wrapper .menu-links {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

/********************************  SITEMAP ***********************************************/

#sitemap ul li {
  list-style: none;
}

#sitemap ul li a {
  font-family: "Lato", sans-serif;
  color: #9dc82d;
  font-size: 22px;
  font-weight: 700;
  padding: 10px 0px;
  text-transform: uppercase;
}

#sitemap ul li ul li a {
  font-family: "Lato", sans-serif;
  color: #474747;
  font-weight: 600;
}

/********************************  NewsBundle ***********************************************/

.news_index .new {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 2px solid #9dc82d;
}

/********************************  SliderBundle ***********************************************/

/* Slider */

.slider_principal {
  margin-bottom: 40px;
}

.slider {
  position: relative;
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-slide {
  float: left;
  overflow: hidden;
  text-align: center;
}

.slick-list {
  overflow: hidden;
}

.slick-next {
  position: absolute;
  z-index: 90;
  top: 50%;
  right: 30px;
  font-size: 0px;
  border: none;
  color: #9dc82d;
  border: 2px solid #9dc82d;
  background: none;
  width: 43px;
  height: 43px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -22px;
  padding-right: 2px;
}

.slick-next:before {
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  content: "\F105";
  font-size: 28px;
}

.slick-prev {
  position: absolute;
  z-index: 90;
  top: 50%;
  left: 30px;
  font-size: 0px;
  border: none;
  color: #9dc82d;
  border: 2px solid #9dc82d;
  background: none;
  width: 43px;
  height: 43px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -22px;
  padding-left: 2px;
}

.slick-prev:before {
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  content: "\F104";
  font-size: 28px;
}

.menu-container {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  background-color: #858585;
  width: 20%;
  padding: 10px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.menu-container .logo-wrapper {
  text-align: center;
  position: relative;
}

.menu-container .logo-wrapper:after {
  content: attr(data-content-after);
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -30px;
  color: #191919;
  font-size: 14px;
}

.menu-container .links-wrapper {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.menu-container .links-wrapper .menu-links {
  margin: 20px 0;
  padding: 0 30px;
  display: block;
  height: 30px;
  line-height: 30px;
  font-size: 17px;
  font-weight: bold;
  color: #fff;
  background: transparent;
  border: 2px solid transparent;
  border-radius: 30px 30px 0 30px;
  text-align: center;
}

.menu-container .links-wrapper .menu-links:hover {
  color: #9dc82d;
}

.menu-container .links-wrapper .menu-links.active {
  height: 50px;
  line-height: 50px;
  background: #9dc82d;
  border-color: #9dc82d;
}

.menu-container .links-wrapper .menu-links.active:hover {
  color: #9dc82d;
  background: #fff;
}

.menu-container .menu-contact-wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.menu-container .menu-contact-wrapper .menu-contact-link {
  position: relative;
  padding-top: 50px;
  color: #9dc82d;
  font-weight: 600;
}

.menu-container .menu-contact-wrapper .menu-contact-link .link-label {
  font-family: "Open Sans", sans-serif !important;
}

.menu-container .menu-contact-wrapper .menu-contact-link:before {
  display: block;
  width: 40px;
  height: 40px;
  border: 3px solid #9dc82d;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
}

.menu-container .social-networks-wrapper {
  display: flex;
  justify-content: center;
}

.menu-container .social-networks-wrapper .social-links {
  font-size: 25px;
  color: #fff;
  padding: 25px;
}

@media all and (max-width: 1400px) {
  .menu-container {
    padding: 30px 0px;
  }

  .menu-container .logo-wrapper {
    padding: 0 15px;
  }
}

@media all and (max-width: 1139px) {
  .menu-container {
    min-width: 250px;
  }

  .menu-container .pt-60 {
    padding-top: 40px;
  }

  .menu-container .pb-60 {
    padding-bottom: 40px;
  }

  .menu-container .pt-50 {
    padding-top: 35px;
  }

  .menu-container .pb-50 {
    padding-bottom: 35px;
  }

  .menu-container .links-wrapper .menu-links {
    margin: 10px 0;
    display: block;
    height: 40px;
    line-height: 40px;
  }

  .menu-container .social-networks-wrapper .social-links {
    padding: 10px 25px;
  }
}

@media all and (max-width: 991px) {
  .menu-container {
    position: relative;
    width: 100%;
    min-height: auto;
    height: auto;
    padding: 10px 10px 15px 10px;
  }

  .menu-container .logo-wrapper {
    max-width: 250px;
    padding-bottom: 30px;
    margin: 0 auto;
  }

  .menu-container .logo-wrapper:after {
    bottom: 0;
    left: 0;
    right: auto;
  }

  .menu-container .links-wrapper {
    display: none;
  }

  .menu-container .social-networks-wrapper {
    position: absolute;
    left: 0;
    top: 55px;
  }

  .menu-container .menu-contact-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-end;
  }

  .menu-container .menu-contact-wrapper .menu-contact-link {
    width: 50px;
  }

  .menu-container .menu-contact-wrapper .menu-contact-link .link-label {
    display: none;
  }
}

@media all and (max-width: 500px) {
  .menu-container .logo-wrapper {
    margin-left: 0;
  }

  .menu-container .social-networks-wrapper {
    display: none;
  }
}

/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main,
.hover-to-green,
.hover-to-white,
.bt_learn_more,
a.file,
input[type=submit],
.block .visual_container .link,
.block .visual_container .zoom,
.contact_view .btn.btn-primary,
.menu-container .links-wrapper .menu-links {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

body.tpl-cookies #tarteaucitronRoot,
body.tpl-cookies #tarteaucitronAlertBig,
body.tpl-cookies .modal-backdrop,
body.tpl-cookies #tarteaucitronPremium {
  display: none !important;
}

body.tpl-cookies.modal,
body.tpl-cookies.modal-open {
  overflow: inherit;
}

#tarteaucitronAlertBig {
  padding-right: 0 !important;
}

#tarteaucitronAlertBig .modal-body {
  padding: 60px;
  font-size: 18px;
}

#tarteaucitronAlertBig .modal-body h2 {
  font-size: 25px;
  margin-bottom: 30px;
  color: #333;
}

#tarteaucitronAlertBig .btn-success {
  border: 1px solid #858585 !important;
  background-color: #858585 !important;
  color: #fff;
  text-transform: uppercase;
  border: 2px solid #fff;
  border-radius: 5px;
  line-height: 50px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .2s ease-in-out;
  white-space: nowrap;
  padding: 0 60px;
}

#tarteaucitronAlertBig .btn-success:hover {
  background-color: #fff !important;
  color: #858585;
  transition: all .2s ease-in-out;
}

.embed-responsive {
  position: relative;
}

.embed-responsive .activate-wrap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
}

.embed-responsive .activate-wrap .tac_float {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  text-align: center;
}

@media all and (max-width: 767px) {
  #tarteaucitronAlertBig .modal-body {
    padding: 10px;
    font-size: 14px;
  }

  #tarteaucitronAlertBig .modal-body .img-fluid {
    max-width: 50px;
  }

  #tarteaucitronAlertBig .modal-body h2 {
    font-size: 22px;
    margin-bottom: 15px;
  }

  #tarteaucitronAlertBig .modal-body #tarteaucitronAcceptContainer {
    margin-top: 20px !important;
  }
}

/* required styles */

.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */

.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */

/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */

.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */

.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

/* marker & overlays interactivity */

.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */

.leaflet-container {
  background: #ddd;
  outline: 0;
}

.leaflet-container a {
  color: #0078A8;
}

.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */

.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */

.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px 'Lucida Console', Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */

.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  background-image: url(./plugins/map/images/layers.png);
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(./plugins/map/images/layers-2x.png);
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */

.leaflet-default-icon-path {
  background-image: url(./plugins/map/images/marker-icon.png);
}

/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover {
  text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */

.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}

.leaflet-popup-content p {
  margin: 18px 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */

/* Base styles for the element that has a tooltip */

.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
  -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
  -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out, -webkit-stroke-opacity 0.3s ease-in;
  -moz-transition: -moz-stroke-dashoffset 0.3s ease-out, -moz-stroke-opacity 0.3s ease-in;
  -o-transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

.marker-cluster-small {
  background-color: rgba(181, 226, 140, 0.6);
}

.marker-cluster-small div {
  background-color: rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
  background-color: rgba(241, 211, 87, 0.6);
}

.marker-cluster-medium div {
  background-color: rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
  background-color: rgba(253, 156, 115, 0.6);
}

.marker-cluster-large div {
  background-color: rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */

.leaflet-oldie .marker-cluster-small {
  background-color: #b5e28c;
}

.leaflet-oldie .marker-cluster-small div {
  background-color: #6ecc39;
}

.leaflet-oldie .marker-cluster-medium {
  background-color: #f1d357;
}

.leaflet-oldie .marker-cluster-medium div {
  background-color: #f0c20c;
}

.leaflet-oldie .marker-cluster-large {
  background-color: #fd9c73;
}

.leaflet-oldie .marker-cluster-large div {
  background-color: #f18017;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

.marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.marker-cluster span {
  line-height: 30px;
}

