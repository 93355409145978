@import "variables";

#header{
    background: $color-main;
    position: relative;

    #logo {
        img{
            max-width: 150px;
        }
    }

    #navigation{
        display: inline-block;
    }
    .container{
        position: relative;
    }
}