
.contact_view {
    .form-group {
        margin-bottom: 25px;
    }
    .form-control {
        background: #e4e4e4;
        border-radius: 30px 30px 0 30px;
        border: none;
        height: 50px;
        color: #858585;
        line-height: 50px;
        padding: 0 30px;
    }
    textarea {
        min-height: 200px;
    }

    label[for='form_rgpdAccept'] {
        font-size: 13px;
    }
    .btn.btn-primary {
        @extend .transition-main;
        height: 50px;
        line-height: 50px;
        padding: 0 45px;
        font-size: 19px;
        border: none;
        background: $c-green;
        color: #fff;
        border-radius: 30px 30px 0 30px;
        text-transform: capitalize;
        border: 2px solid $c-green;

        &:hover {
            color: $c-green;
            background: #fff;
        }
    }
}