
.txt-green {
    color: $c-green;
}

.txt-grey {
    color: $c-grey;
}

.txt-white {
    color: #fff;
}

.bg-green {
    background-color: $c-green;
}

.bg-grey {
    background-color: $c-grey;
}

.bg-light-grey {
    background-color: #e4e4e4;
}

.bg-white {
    background-color: #fff;
}

.light {
    font-weight: 300;
}
.normal {
    font-weight: 400;
}
.semi-bold {
    font-weight: 600;
}
.bold {
    font-weight: 700;
}

.border-vertige {
    border-radius: 25px 25px 0 25px;
}

.vague-container {
    display: block;
    margin: 0 -2px;
}

.vague {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: -1px;
    left: 0;
}

.f-small {
    font-size: $text-size-small;
}
.f-normal {
    font-size: $text-size-main;
}

.f-intro {
    font-size: $text-size-intro;
}

.f-big {
    font-size: $text-size-big;
}

.f-bigger {
    font-size: 30px;
}

.f-title {
    font-size: 50px;
}

.vertige-title {
    position: relative;
    font-size: $text-size-h1;
    text-align: center;
    padding-top: 35px;
    
    .title-before {
        font-weight: bold;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}

.pb-50 {
    padding-bottom: 50px;
}
.pt-50 {
    padding-top: 50px;
}
.pb-60 {
    padding-bottom: 60px;
}
.pt-60 {
    padding-top: 60px;
}
.pb-70 {
    padding-bottom: 70px;
}
.pt-70 {
    padding-top: 70px;
}

.mt-80 {
    margin-top: 80px;
}

.hover-to-green {
    @extend .transition-main;
    &:hover {
        color: $c-green !important;
        border-color: $c-green !important;
    }
}

.hover-to-white {
    @extend .transition-main;
    &:hover {
        color: $c-white !important;
        border-color: $c-white !important;
    }
}

@media all and (max-width: 767px) {
    .vertige-title {
        font-size: 30px;
        padding-top: 24px;

        .title-before {
            font-weight: bold;
            font-size: 17px;
        }
    }

    .f-small {
        font-size: 14px;
    }
    .f-normal {
        font-size: 16px;
    }

    .f-intro {
        font-size: 18px;
    }

    .f-big {
        font-size: 18px;
    }

    .f-bigger {
        font-size: 20px;
    }

    .f-title {
        font-size: 30px;
    }
    .pb-50 {
        padding-bottom: 25px;
    }
    .pt-50 {
        padding-top: 25px;
    }
    .pb-60 {
        padding-bottom: 30px;
    }
    .pt-60 {
        padding-top: 30px;
    }
    .pb-70 {
        padding-bottom: 35px;
    }
    .pt-70 {
        padding-top: 35px;
    }

    .mt-80 {
        margin-top: 1.5rem!important;
    }
}





