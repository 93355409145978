
// Gallery Slider (home)

@media all and (max-width: 480px) {
    #gallery_bloc {
        padding-bottom: 90px;
        position: relative;
        background: $c-green;

        .slick-prev, .slick-next {
            bottom: 30px;
            top: auto;
            color: #fff;
            border-color: #fff;
        }
    }
}
