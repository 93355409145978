@import "variables";

.zone.container{
    .block_separator{
        border-bottom: 3px solid $color-secondary;
        margin-bottom: 30px;
    }
}

.zone.row{
    .block_separator{
        -webkit-box-shadow:inset 0 50px 70px -70px #000000;
        box-shadow:inset 0 50px 70px -70px #000000;
        min-height: 70px;
    }
}
.block{
    margin-bottom: 30px;

    .visual_container{
        position: relative;

        .link, .zoom{
            position: absolute;
            top:0px;
            left: 0px;
            width: 100%;
            height: 100%;
            display: block;
            opacity: 0;
            background: url('../../../../images/link.png') center center $color-secondary no-repeat;
            @extend .transition-main;
        }
        .zoom{
            background: url('../../../../images/zoom.png') center center $color-secondary no-repeat;
        }
        &:hover{
            .link, .zoom{
                opacity: 0.7;
            }

        }
    }

    .body a{
        color: $color-secondary;
    }
}
.block_entete{
    width: 100%;
    position: relative;

}

.chiffre, .chiffres{
    text-align: center;
    padding: 30px 0px;

    .col{
        border-left: 2px solid $color-secondary;

        h2{
            font-size: 50px;
        }
        .introduction{
            font-size: 24px;
            color: $color-secondary;
        }
    }
    .col:nth-child(1){
        border:none;
    }
}
